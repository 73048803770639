#sitewide-banner-text-desktop p, #sitewide-banner-text-mobile p {
  margin-bottom: 0 !important;
}

#sitewide-banner-text-desktop p a, #sitewide-banner-text-mobile p a {
  color: #fff;
  text-decoration: underline;
}

/*# sourceMappingURL=index.css.map */
